<template>
  <el-container style="height: calc(100vh)">
    <el-main style="width: 75%; height: calc(100vh); padding: 0 !important">
      <el-container style="height: calc(100vh)">
        <el-aside class="aside" width="150px">
          <el-menu class="menu">
            <el-menu-item
              v-for="(item, key) in item"
              :key="key"
              class="menu_item"
            >
              <template slot="title">
                <div
                  :class="activeItem === item.value ? 'nav1' : 'nav'"
                  @click="getkey(item.value)"
                >
                  <span>{{ item.text }}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container v-if="activeItem == 0" class="content_big">
          <el-aside class="content_big_aside">
            <div class="content_big_title">合伙设置</div>
            <el-menu class="content_big_menu">
              <div style="margin-bottom:30px;">股东合伙人设置</div>
              <table>
                <tr>
                  <th>股金</th>
                  <th>分红比例</th>
                  <th>操作</th>
                </tr>
                <tr>
                  <td>
                    <el-input
                      v-model="item.num" class="th_inp" size="mini"  placeholder="请填写的数量">
                      <span  class="spl" slot="suffix">元</span>
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="item.num" class="th_inp" size="mini"  placeholder="请填写的数量">
                      <span  class="spl" slot="suffix">%</span>
                    </el-input>
                  </td>
                  <td class="t1_img_big">
                   <img src="@/assets/images/imggg/cuo.png" alt="" class="t1_img">
                  </td>
                </tr>
              </table>
              <div><button class="butat2">+添加</button></div>
              <div class="t1_radio">
                是否允许绑定已有用户 
                <el-radio v-model="radio" label="1">是</el-radio>
                <el-radio v-model="radio" label="2">否</el-radio>
              </div>
              <button class="butat1">保存设置</button>
            </el-menu>
            <el-menu class="content_big_menu">
              <div style="margin-bottom:30px;">员工合伙人设置</div>
              <table>
                <tr>
                  <th>月业绩范围</th>
                  <th>提成比例</th>
                  <th>操作</th>
                </tr>
                <tr>
                  <td>
                    <el-input
                      v-model="item.num" class="th_inp" size="mini"  placeholder="请填写的数量">
                      <span class="spl" slot="suffix">元</span>
                    </el-input>
                  </td>
                  <td>
                    <el-input
                      v-model="item.num" class="th_inp" size="mini"  placeholder="请填写的数量">
                      <span  class="spl" slot="suffix">%</span>
                    </el-input>
                  </td>
                  <td class="t1_img_big">
                   <img src="@/assets/images/imggg/cuo.png" alt="" class="t1_img">
                  </td>
                </tr>
              </table>
              <div><button class="butat2">+添加</button></div>
              <div class="t1_radio">
                是否允许绑定已有用户 
                <el-radio v-model="radio" label="1">是</el-radio>
                <el-radio v-model="radio" label="2">否</el-radio>
              </div>
              <button class="butat1">保存设置</button>
            </el-menu>
            <el-menu class="content_big_menu">
              <div style="margin-bottom:30px;">会员合伙人设置</div>
              <div>佣金比例
                <el-input 
                      v-model="item.num" class="th_inp t3_input" size="mini"  placeholder="">
                      <span slot="suffix"  class="spl" >%</span>
                    </el-input>
              </div>
              <button class="butat1">保存设置</button>
            </el-menu>
          </el-aside>
        </el-container>
        <el-container v-if="activeItem == 1" class="content_big">
          <el-aside class="content_big_aside"> 
            <div class="content_big_title">股东合伙人</div>
            <el-menu class="content_big_menu1">
              <div>
                <button class="title_but" @click="dialogVisible1 = true">+添加股东</button>
                <div class="content_big_menu2_click">
                  <div
                    :class="click_statex === 1 ? 'click_2' : 'click_1'"
                    @click="getclick_st(1)"
                  >
                    现有股东
                  </div>
                  <div
                    :class="click_statex === 2 ? 'click_2' : 'click_1'"
                    @click="getclick_st(2)"
                  >
                    历史股东
                  </div>
                </div>
              </div>
              <div>
                请输入股东手机号：<el-input
                  v-model="item.num"
                  class="th_inp"
                  size="mini"
                  placeholder="请输入股东手机号"
                ></el-input>
                <button class="but1">查询</button>
                <button class="but2">取消</button>
              </div>
              <div class="table_title">
                <div>股东姓名</div>
                <div>手机号</div>
                <div>添加时间</div>
                <div>到期时间</div>
                <div>股金</div>
                <div>分红收益</div>
                <div>操作</div>
              </div>
              <table class="table_big">
                <tr>
                  <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                </tr>
                <tr class="table_big_nono">
                  <div class="table_big_min">
                    <div>
                      <img src="@/assets/images/index/nonono.png" alt="" />
                    </div>
                    <div>暂无数据</div>
                  </div>
                </tr>
              </table>
            </el-menu>
          </el-aside>
        </el-container>
        <el-container v-if="activeItem == 2" class="content_big">
          <el-aside class="content_big_aside">
            <div class="content_big_title">自定义合伙人</div>
            <el-menu class="content_big_menu2">
              <div>
                <button class="title_but" @click="dialogVisible2 = true">+添加合伙人</button>
                <div class="content_big_menu2_click">
                  <div
                    :class="click_state === 1 ? 'click_2' : 'click_1'"
                    @click="click_st(1)"
                  >
                    现有合伙人
                  </div>
                  <div
                    :class="click_state === 2 ? 'click_2' : 'click_1'"
                    @click="click_st(2)"
                  >
                    历史合伙人
                  </div>
                </div>
              </div>
              <div>
                请输入合伙人手机号：<el-input
                  v-model="item.num"
                  class="th_inp"
                  size="mini"
                  placeholder="请输入合伙人手机号"
                ></el-input>
                <button class="but1">查询</button>
                <button class="but2">取消</button>
              </div>
              <div class="table_title">
                <div>合伙人姓名</div>
                <div>手机号</div>
                <div>添加时间</div>
                <div>到期时间</div>
                <div>自定义名称</div>
                <div>自定义比例</div>
                <div>分红收益</div>
                <div>操作</div>
              </div>
              <table class="table_big">
                <tr>
                  <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                </tr>
                <tr class="table_big_nono">
                  <div class="table_big_min">
                    <div>
                      <img src="@/assets/images/index/nonono.png" alt="" />
                    </div>
                    <div>暂无数据</div>
                  </div>
                </tr>
              </table>
            </el-menu>
          </el-aside>
        </el-container>
        <el-container v-if="activeItem == 3" class="content_big">
          <el-aside class="content_big_aside">
            <div class="content_big_title">员工合伙人</div>
            <el-menu class="content_big_menu3">
              <div>
                <button class="title_but" @click="dialogVisible3 = true">
                  +添加员工合伙人
                </button>
              </div>
              <div>
                合伙人手机号：<el-input
                  v-model="item.num"
                  class="th_inp"
                  size="mini"
                  placeholder="请输入合伙人手机号"
                ></el-input>
                <button class="but1">查询</button>
                <button class="but2">取消</button>
              </div>
              <div class="table_title">
                <div>员工姓名</div>
                <div>手机号</div>
                <div>添加时间</div>
                <div>业绩收益</div>
                <div>操作</div>
              </div>
              <table class="table_big">
                <tr>
                  <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                </tr>
                <tr class="table_big_nono">
                  <div class="table_big_min">
                    <div>
                      <img src="@/assets/images/index/nonono.png" alt="" />
                    </div>
                    <div>暂无数据</div>
                  </div>
                </tr>
              </table>
            </el-menu>
          </el-aside>
        </el-container>
        <el-container v-if="activeItem == 4" class="content_big">
          <el-aside class="content_big_aside">
            <div class="content_big_title">会员合伙人</div>
            <el-menu class="content_big_menu4">
              <div><button class="title_but" @click="dialogVisible4 = true">+添加会员合伙人</button></div>
              <div>
                合伙人手机号：<el-input
                  v-model="item.num"
                  class="th_inp"
                  size="mini"
                  placeholder="请输入合伙人手机号"
                ></el-input>
                <button class="but1">查询</button>
                <button class="but2">取消</button>
              </div>
              <div class="table_title">
                <div>会员合伙人</div>
                <div>手机号</div>
                <div>添加时间</div>
                <div>佣金收益</div>
                <div>可提现佣金</div>
                <div>操作</div>
              </div>
              <table class="table_big">
                <tr>
                  <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                </tr>
                <tr class="table_big_nono">
                  <div class="table_big_min">
                    <div>
                      <img src="@/assets/images/index/nonono.png" alt="" />
                    </div>
                    <div>暂无数据</div>
                  </div>
                </tr>
              </table>
            </el-menu>
          </el-aside>
        </el-container>
        <el-container v-if="activeItem == 5" class="content_big">
          <el-aside class="content_big_aside">
            <div class="content_big_title">提现记录</div>
            <el-menu class="content_big_menu5">
              <div>
                合伙人手机号：<el-input
                  v-model="item.num"
                  class="th_inp"
                  size="mini"
                  placeholder="请输入合伙人手机号"
                ></el-input>
                <button class="but1">查询</button>
                <button class="but2">取消</button>
              </div>
              <div class="content_big_menu5_div">
                <div
                  :class="state === 1 ? 'div_2' : 'div_1'"
                  @click="getstate(1)"
                >
                  待审核
                </div>
                <div
                  :class="state === 2 ? 'div_2' : 'div_1'"
                  @click="getstate(2)"
                >
                  历史记录
                </div>
              </div>
              <div class="table_title">
                <div>时间</div>
                <div>合伙会员</div>
                <div>提现金额</div>
                <div>状态</div>
                <div>操作</div>
              </div>
              <table class="table_big">
                <tr>
                  <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                </tr>
                <tr class="table_big_nono">
                  <div class="table_big_min">
                    <div>
                      <img src="@/assets/images/index/nonono.png" alt="" />
                    </div>
                    <div>暂无数据</div>
                  </div>
                </tr>
              </table>
            </el-menu>
          </el-aside>
        </el-container>
        <el-container v-if="activeItem == 6" class="content_big">
          <el-aside class="content_big_aside">
            <div class="content_big_title">海报库</div>
            <el-menu class="content_big_menu6">
              <el-button type="primary">+添加海报</el-button>
              <div class="table_title">
                <div>id</div>
                <div>背景图片</div>
                <div>文案</div>
                <div>操作</div>
              </div>
              <table class="table_big">
                <tr>
                  <!-- <td>1</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td> -->
                </tr>
                <tr class="table_big_nono">
                  <div class="table_big_min">
                    <div>
                      <img src="@/assets/images/index/nonono.png" alt="" />
                    </div>
                    <div>暂无数据</div>
                  </div>
                </tr>
              </table>
            </el-menu>
          </el-aside>
        </el-container>
        <div>
             <!-- 点击打开添加股东 -->
             <el-dialog title="添加股东合伙人" :visible.sync="dialogVisible1" width="42%" >
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="手机号">
                <el-input v-model="formInline.user" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="股东姓名">
                <el-input v-model="formInline.user" placeholder="请输入股东姓名"></el-input>
              </el-form-item>
              <el-form-item label="有效期至">
                <el-input v-model="formInline.user" placeholder="请输入自定义比例"></el-input>
              </el-form-item>
              <el-form-item label="选择股金">
                <el-input v-model="formInline.user" placeholder="请输入自定义比例"></el-input>
              </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible1= false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible1 = false">保存</el-button>
            </span>
          </el-dialog>
            <!-- 点击打开添加自定义合伙人 -->
             <el-dialog title="添加自定义合伙人" :visible.sync="dialogVisible2" width="42%" >
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="手机号">
                <el-input v-model="formInline.user" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="合伙人姓名">
                <el-input v-model="formInline.user" placeholder="请输入合伙人姓名"></el-input>
              </el-form-item>
              <el-form-item label="自定义名称">
                <el-input v-model="formInline.user" placeholder="请输入自定义名称"></el-input>
              </el-form-item>
               <el-form-item label="自定义比例">
                <el-input v-model="formInline.user" placeholder="请输入自定义比例"></el-input>
              </el-form-item>
              <el-form-item label="有效期至">
                <el-input v-model="formInline.user" placeholder="请输入自定义比例"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible2 = false">保存</el-button>
            </span>
          </el-dialog>
            <!-- 点击打开添加员工合伙人 -->
          <el-dialog title="添加员工合伙人" :visible.sync="dialogVisible3" width="42%" >
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="手机号">
                <el-input v-model="formInline.user" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="员工姓名">
                <el-input v-model="formInline.user" placeholder="请输入员工姓名"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible3 = false">保存</el-button>
            </span>
          </el-dialog>
            <!-- 点击打开添加会员合伙人 -->
          <el-dialog title="添加会员合伙人" :visible.sync="dialogVisible4" width="42%" >
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="手机号">
                <el-input v-model="formInline.user" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="员工姓名">
                <el-input v-model="formInline.user" placeholder="请输入员工姓名"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible3 = false">保存</el-button>
            </span>
          </el-dialog>
        </div>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      item: [
        { value: 0, text: "合伙设置" },
        { value: 1, text: "股东合伙人" },
        { value: 2, text: "自定义合伙人" },
        { value: 3, text: "员工合伙人" },
        { value: 4, text: "会员合伙人" },
        { value: 5, text: "提现记录" },
        { value: 6, text: "海报库" },
      ],
      activeItem: 0,
      state: 1,
      click_state: 1,
      click_statex: 1,
       dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      formInline: {
        iphone: "",
        user: "",
      },
      resource: [
        { required: true, message: '请选择活动资源', trigger: 'change' }
      ],
       radio: '1',
    };
  },

  watch: {},

  mounted() {},
  methods: {
    getkey(key) {
      this.activeItem = key;
    },
    getstate(key) {
      this.state = key;
    },
    click_st(key) {
      this.click_state = key;
    },
    getclick_st(key) {
      this.click_statex = key;
    },

  },
};
</script>
<style lang="less" scoped>
// 左侧导航
.aside {
  background-color: white;
  border-right: solid 1px #e6e6e6;
  .nav {
    text-align: center;
    span {
      border: 1px solid transparent;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }
  .nav1 {
    text-align: center;
    background-color: white;
    span {
      color: #409eff;
      border: 1px solid #409eff;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }
  .menu_item {
    background-color: white;
  }
  .el-menu {
    border: none !important;
    padding-top: 35px;
    
  }
}
.content_big {
  width: 100%;
  height: 100%;
  padding: 20px;
  .content_big_aside {
    width: 100% !important;
    .content_big_title {
      width: 100%;
      font-size: 25px;
      padding: 20px 0px;
    }
    .content_big_menu {
      width: 100%;
      padding: 30px;
      margin-bottom: 30px;
      .butat2{
        width: 70px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        color: #409eff;
        border:1px solid  #409eff;
        background-color: white;
        margin-top: 10px;
      }
      .butat1{
      width: 100px;
      line-height: 35px;
      color: white;
      background-color: #409eff;
      height: 35px;
      border: none;
       text-align: center;
    }
    .t1_radio{
      margin: 20px 0px;
    }
    .t1_img_big{
      text-align: center;
    }
    .t1_img{
      width: 20px;
      height: 20px;
    }
    }
    .content_big_menu1 {
      width: 95%;
      padding: 30px;
      .th_inp {
        width: 200px;
      }
      .content_big_menu2_click {
        display: inline-block;
        margin-left: 35px;
        div {
          display: inline-block;
          width: 100px;
          height: 33px;
          line-height: 33px;
          text-align: center;
        }
        .click_1 {
          border: 1px solid #999999;
        }
        .click_2 {
          background-color: #409eff;
          border: 1px solid #409eff;
          color: white;
        }
      }
      .title_but {
        width: 130px;
        text-align: center;
        background-color: #409eff;
        color: white;
        height: 35px;
        line-height: 35px;
        border: none;
        margin-bottom: 30px;
      }

      .but1 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        border: none;
        background-color: #409eff;
        color: white;
        border-radius: 3px;
        margin: 0px 20px;
      }
      .but2 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        // border: none;
        background-color: white;
        border-radius: 3px;
        border: 1px solid #999999;
        color: #999999;
      }
      .table_title {
        width: 100%;
        height: 45px;
        line-height: 45px;
        margin-top: 30px;
        background-color: #f9f9f9;
        div {
          display: inline-block;
          width: 14.2% !important;
          text-align: center !important;
        }
      }
      .table_big {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        td {
          text-align: center;
          height: 35px;
          line-height: 35px;
        }
        .table_big_nono {
          width: 100%;
          .table_big_min {
            padding: 70px 0px;
          }
          div {
            width: 100%;
            text-align: center;
            color: #cdcdcd;
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
    .content_big_menu2 {
      width: 95%;
      padding: 30px;
      .th_inp {
        width: 200px;
      }
      .content_big_menu2_click {
        display: inline-block;
        margin-left: 35px;
        div {
          display: inline-block;
          width: 100px;
          height: 33px;
          line-height: 33px;
          text-align: center;
        }
        .click_1 {
          border: 1px solid #999999;
        }
        .click_2 {
          background-color: #409eff;
          border: 1px solid #409eff;
          color: white;
        }
      }
      .title_but {
        width: 130px;
        text-align: center;
        background-color: #409eff;
        color: white;
        height: 35px;
        line-height: 35px;
        border: none;
        margin-bottom: 30px;
      }

      .but1 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        border: none;
        background-color: #409eff;
        color: white;
        border-radius: 3px;
        margin: 0px 20px;
      }
      .but2 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        // border: none;
        background-color: white;
        border-radius: 3px;
        border: 1px solid #999999;
        color: #999999;
      }
      .table_title {
        width: 100%;
        height: 45px;
        line-height: 45px;
        margin-top: 30px;
        background-color: #f9f9f9;
        div {
          display: inline-block;
          width: 12.5% !important;
          text-align: center !important;
        }
      }
      .table_big {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        td {
          text-align: center;
          height: 35px;
          line-height: 35px;
        }
        .table_big_nono {
          width: 100%;
          .table_big_min {
            padding: 70px 0px;
          }
          div {
            width: 100%;
            text-align: center;
            color: #cdcdcd;
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
    .content_big_menu3 {
      width: 95%;
      padding: 30px;
      .th_inp {
        width: 200px;
      }
      .title_but {
        width: 130px;
        text-align: center;
        background-color: #409eff;
        color: white;
        height: 35px;
        line-height: 35px;
        border: none;
        margin-bottom: 30px;
      }
      .but1 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        border: none;
        background-color: #409eff;
        color: white;
        border-radius: 3px;
        margin: 0px 20px;
      }
      .but2 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        // border: none;
        background-color: white;
        border-radius: 3px;
        border: 1px solid #999999;
        color: #999999;
      }
      .table_title {
        width: 100%;
        height: 45px;
        line-height: 45px;
        margin-top: 30px;
        background-color: #f9f9f9;
        div {
          display: inline-block;
          width: 20% !important;
          text-align: center !important;
        }
      }
      .table_big {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        td {
          text-align: center;
          height: 35px;
          line-height: 35px;
        }
        .table_big_nono {
          width: 100%;
          .table_big_min {
            padding: 70px 0px;
          }
          div {
            width: 100%;
            text-align: center;
            color: #cdcdcd;
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
    .content_big_menu4 {
      width: 95%;
      padding: 30px;
      .th_inp {
        width: 200px;
      }
      .title_but {
        width: 130px;
        text-align: center;
        background-color: #409eff;
        color: white;
        height: 35px;
        line-height: 35px;
        border: none;
        margin-bottom: 30px;
      }
      .but1 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        border: none;
        background-color: #409eff;
        color: white;
        border-radius: 3px;
        margin: 0px 20px;
      }
      .but2 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        // border: none;
        background-color: white;
        border-radius: 3px;
        border: 1px solid #999999;
        color: #999999;
      }
      .table_title {
        width: 100%;
        height: 45px;
        line-height: 45px;
        margin-top: 30px;
        background-color: #f9f9f9;
        div {
          display: inline-block;
          width: 16.6% !important;
          text-align: center !important;
        }
      }
      .table_big {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        td {
          text-align: center;
          height: 35px;
          line-height: 35px;
        }
        .table_big_nono {
          width: 100%;
          .table_big_min {
            padding: 70px 0px;
          }
          div {
            width: 100%;
            text-align: center;
            color: #cdcdcd;
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
    .content_big_menu5 {
      width: 95%;
      padding: 30px;
      .content_big_menu5_div {
        margin-top: 30px;
        div {
          display: inline-block;
          width: 100px;
          text-align: center;
          border: 1px solid #999999;
          height: 35px;
          line-height: 35px;
        }
        .div_2 {
          display: inline-block;
          width: 100px;
          text-align: center;
          border: 1px solid #409eff;
          height: 35px;
          line-height: 35px;
          background-color: #409eff;
          color: white;
        }
      }
      .th_inp {
        width: 200px;
      }
      .but1 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        border: none;
        background-color: #409eff;
        color: white;
        border-radius: 3px;
        margin: 0px 20px;
      }
      .but2 {
        width: 70px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        // border: none;
        background-color: white;
        border-radius: 3px;
        border: 1px solid #999999;
        color: #999999;
      }
      .table_title {
        width: 100%;
        height: 45px;
        line-height: 45px;
        margin-top: 30px;
        background-color: #f9f9f9;
        div {
          display: inline-block;
          width: 20% !important;
          text-align: center !important;
        }
      }
      .table_big {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        td {
          text-align: center;
          height: 35px;
          line-height: 35px;
        }
        .table_big_nono {
          width: 100%;
          .table_big_min {
            padding: 70px 0px;
          }
          div {
            width: 100%;
            text-align: center;
            color: #cdcdcd;
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }

    .content_big_menu6 {
      width: 95%;
      padding: 30px;
      .table_title {
        width: 100%;
        height: 45px;
        line-height: 45px;
        margin-top: 30px;
        background-color: #f9f9f9;
        div {
          display: inline-block;
          width: 25% !important;
          text-align: center !important;
        }
      }
      .table_big {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        td {
          text-align: center;
          height: 35px;
          line-height: 35px;
        }
        .table_big_nono {
          width: 100%;
          .table_big_min {
            padding: 70px 0px;
          }
          div {
            width: 100%;
            text-align: center;
            color: #cdcdcd;
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
  }
}
.t3_input{
  width: 80px;
  margin-bottom:30px ;
}
.el-input__suffix{
line-height: 28px;
}
.spl{
  line-height: 28px !important; 
}
</style>
